
import { defineComponent, computed, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useBus } from '@/bus';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { VueCookieNext } from "vue-cookie-next";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { format } from 'date-fns-tz';
import { ElNotification } from 'element-plus';
export default defineComponent({
name: "Assign Digitizer",
components: {
  Form,
  Field,
  ErrorMessage,
  Datatable,

},
data() {
  return {
    loading: false,
    taskList: [] as any,
    initData:[] as any,
    taskKeyUpdate: 0,
    search: '',
    componentKey:0,
    user:[] as any,
    employee_id: '' as any,
    role_id:'' as any,
    all_cookies: '' as any,
    qc_type:'' as any,
    forward_assigned_to:[] as any,
    employeeList: '' as any,
    load: false,
    taskDetailData: [] as any,
    fileList: [] as any,
    upazila_name_eng:'' as any,
    grid_name:'' as any,
    files: [] as any,
    deadline:[] as any,
    task_type: '' as any,
    feedbackFiles: [] as any,
    forwardToArray: [] as any,
    date: new Date(),
    tableHeader: [
      {
        name: 'TASK No.',
        key: 'index',
      },
      {
        name: 'UPAZILLA',
        key: 'upazila_name',
      },
      {
        name: 'Mouza',
        key: 'mouza_name',
      },
      {
        name: 'DEADLINE',
        key: 'selectDeadline',
      },
      {
        name: 'ASSIGNED TO',
        key: 'assigned_to',
      },
      {
        name: 'ASSIGNED BY',
        key: 'assigned_by',
      },
      {
        name: 'ASSIGNING DATE',
        key: 'assigning_date',
      },
      {
        name: 'FIRST UPLOADING DATE',
        key: 'first_uploading_date',
      },
      {
        name: 'LAST MODIFICATION DATE',
        key: 'last_modification_date',
      },
      {
        name: 'CONFIRMATION DATE',
        key: 'confirmation_date',
      },
      {
        name: 'Select Digitizer User',
        key: 'digitizer_user',
      },
      {
        name: 'Give Deadline',
        key: 'deadline',
      },
      {
        name: 'Actions',
        key: 'actions',
      },
    ]
  };
},
async created() {
  await this.getTaskList();
  this.initData= this.taskList;
  await this.getRoleEmployee();

},
methods: {
  formatDate(dateString) {
      return dateString ? format(new Date(dateString), 'dd-MMM-yyyy') : '';
    },
  async addToForward(task_detail_id){
    const forward_assigned_to= this.forward_assigned_to[task_detail_id];
    
    if(this.deadline[task_detail_id])
    {
      const date= this.deadline[task_detail_id];
        let tempObject = {
        'task_detail_id': task_detail_id,
        'date': date,
        'forward_assigned_to': forward_assigned_to
      };
      this.forwardToArray.push(tempObject);
      console.log(this.forwardToArray);
    }
    else{

    }

  },
  async getTaskList() {
    this.loading=true;
    this.user= VueCookieNext.getCookie("_seip_employee_id");
    this.employee_id= VueCookieNext.getCookie("_seip_employee_id");
    this.role_id= VueCookieNext.getCookie("_seip_role_id");
    console.log(this.employee_id);
    await ApiService.get("configurations/task/gis/list?employee_id="+ this.employee_id + 
    "&role_id="+this.role_id+"&task_type=1"+"&group_id=2")
      .then((response) => {
        this.taskList = response.data.data;
        console.log(this.taskList);
        this.taskKeyUpdate+=1;
        console.log(this.taskList);
        this.loading=false
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  async getRoleEmployee() {
      await ApiService.get("configurations/employee/roleList?role_id=42")
        .then((response) => {
          this.employeeList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  async formSubmit(taskDetail) { 
      let formData = new FormData();
      formData.set('assigned_to', this.forward_assigned_to);
      formData.set('assigned_by', this.employee_id);
      formData.set('deadline', this.deadline);
      formData.set('task_type', '10');
      formData.set('task_detail_id', taskDetail);
      
      
      this.loading = true;
      await ApiService.post('configurations/task/forward',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            Swal.fire({
                text:  'Save Successfully.',
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                confirmButton: "btn fw-semobold btn-success",
              },
            }).then(() => {
              this.$router.push('/dash');
            });
          }
          else
          {
            Swal.fire({
                text:  'Something Went Wrong!',
                icon: "warning",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                confirmButton: "btn fw-semobold btn-danger",
              },
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
    async forward() { 
      let formData = new FormData();
      formData.set('forward_to_array', JSON.stringify(this.forwardToArray));
      formData.set('assigned_by', this.employee_id);
      this.loading = true;
      await ApiService.post('configurations/task/bulk-forward',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            ElNotification({
              dangerouslyUseHTMLString: true,
              message:
              '<i class="fa fa-check" aria-hidden="true"></i> <b>Task has forward successfully!</b>',
            });
            window.location.href = '/dash';
           
          }
          else
          {
            ElNotification({
              dangerouslyUseHTMLString: true,
              message:
              '<i class="fa fa-warning" aria-hidden="true"></i> <b>Something Went Wrong!</b>',
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
  taskView(taskDetail){
    const { bus } = useBus();
    let value = {
      taskDetail: taskDetail,
    };
    bus.emit('task-data-view', value);
  },
  assignQc(taskDetail){
    const { bus } = useBus();
    let value = {
      taskDetail: taskDetail,
    };
    bus.emit('task-data-assign', value);
  },
  taskEdit(task){
    const { bus } = useBus();
    let value = {
      task: task,
    };
    bus.emit('task-data-edit', value);
  },
  taskUpload(task){
    const { bus } = useBus();
    let value = {
      task: task,
    };
    bus.emit('task-data-upload', value);
  },

  filterTasks() {
    if(this.search=='')
    {
      this.taskList= this.initData;
      this.taskKeyUpdate +=1;
    }
    const tasks = this.taskList.filter(task => {
      return (
        task.deadline.toLowerCase().includes(this.search.toLowerCase()) ||
        task?.upazila_info?.upazila_name_eng.toLowerCase().includes(this.search.toLowerCase()) ||
        task?.grid_info?.grid_name.toLowerCase().includes(this.search.toLowerCase()) ||
        task?.employee_info?.name.toLowerCase().includes(this.search.toLowerCase())
      );
    });

    this.taskList= tasks;
    this.taskKeyUpdate +=1;
  },
},
});
